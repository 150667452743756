<template>
  <section>
    <div class="main">
      <div>
        <div class="mb-4">
          <VProgressCircular
            indeterminate
            color="primary"
            :width="3"
            :size="45"
          />
        </div>
        <div>
          <p class="textTitle">
            Loading...
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';

.main {
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
