<template>
  <section>
    <VToolbar
      class="pt-2 pb-2"
      color="primary"
      flat
      dense
      dark
    >
      <VToolbarTitle id="toolbarTitle">
        {{ pageTitle }}
      </VToolbarTitle>
    </VToolbar>
    <div v-if="this.$store.state.attendance !== null">
      <section id="main">
        <div id="content">
          <VCard class="pa-3 shadow">
            <p class="textSubTitle mb-2">
              Select Month
            </p>
            <VSelect
              v-model="month"
              :items="attMonth"
              :hide-details="true"
              background-color="background"
              label="Month"
              flat
              solo
              :loading="isLoading"
            />
          </VCard>
          <VCard
            v-if="this.$store.state.attendance.status==='success'"
            class="shadow pb-3"
          >
            <p class="textSubTitle mt-3 mb-2 pa-3">
              Attendance Record
            </p>
            <VListTile
              v-for="(attendance, index) in this.$store.state.attendance.data"
              :key="index"
            >
              <VListTileContent>
                <VListTileTitle class="textMed">
                  {{ attendance.data.date }}
                </VListTileTitle>
                <VListTileSubTitle class="textBold textSmall text-uppercase">
                  {{ attendance.data.status }}
                </VListTileSubTitle>
              </VListTileContent>

              <VListTileAction>
                <VIcon>
                  {{ attendance.data.status === 'present' ? 'done' : 'close' }}
                </VIcon>
              </VListTileAction>
            </VListTile>
          </VCard>
          <VCard
            v-else
            class="shadow mt-3"
          >
            <NoAttendance />
          </VCard>
        </div>
      </section>
    </div>
    <div
      v-else
      id="loading"
    >
      <Loading />
    </div>
  </section>
</template>

<script>

import moment from 'moment';
import axios from 'axios';
import api from '../configuration/api';
import Loading from '../components/Loading.vue';
import NoAttendance from '../components/NoAttendance.vue';


export default {
  components: {
    Loading,
    NoAttendance,
  },

  data: () => ({
    pageTitle: 'attendance',
    attMonth: [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August', 'September',
      'October', 'November', 'December',
    ],
    attData: '',
    month: moment().format('MMMM'),
    isLoading: false,
  }),

  watch: {
    month(val) {
      this.isLoading = true;
      axios.post(`${api}/attendance/view`, {
        month: val,
        admNo: this.$store.state.studentInfo.admNo,
      }).then((res) => {
        this.attData = res.data;
        this.$store.commit('setAttendance', this.attData);
        this.isLoading = false;
      }).catch(() => {
        this.retry();
      });
    },
  },

  beforeMount() {
    this.isLoading = true;
    axios.post(`${api}/attendance/view`, {
      month: moment().format('MMMM'),
      admNo: this.$store.state.studentInfo.admNo,
    }).then((res) => {
      this.attData = res.data;
      this.$store.commit('setAttendance', this.attData);
      this.isLoading = false;
    }).catch(() => {
      this.retry();
    });
  },

  methods: {
    retry() {
      setTimeout(() => {
        axios.post(`${api}/attendance/view`, {
          month: moment().format('MMMM'),
          admNo: this.$store.state.studentInfo.admNo,
        }).then((res) => {
          this.attData = res.data;
          this.$store.commit('setAttendance', this.attData);
        })
          .catch(() => {
            setTimeout(() => {
              this.retry();
            }, 5000);
          });
      }, 2500);
    },
  },
};

</script>

<style lang="stylus" scoped>
@import '../assets/stylus/text';
@import '../assets/stylus/attendance';
</style>
